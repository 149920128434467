








































import {Vue, Component, Watch} from "vue-property-decorator";
import DepositType from "./Deposit";
import ZoomPage from "@/impView/PageSafety";
import HeadTop from "@/components/ShareComponent/HeadTop.vue";
import MoneyInputItem from "@/views/Money/components/InputItem/index.vue";
import InputItem from "@/views/Money/Deposit/components/InputItem/index.vue";
import {setEncrypt} from "@/views/Money/Deposit/util";
import {getDefaultAccount, setApplyMoney} from './Server';
import {Dialog} from "vant";
import {AccountListStore} from "@/views/Money/AccountList/Store";

@Component({name: "Deposit",components:{ HeadTop,MoneyInputItem,InputItem }})
export default class Deposit extends ZoomPage implements DepositType{
    account = ""
    name = ""
    rawName = ""
    money = ""
    deposit = 0
    data:any = {}

    mounted(){
        this.loadingShow = false
    }

    activated(){
        let { money = "0" } = this.$route.query
        this.deposit = Number( money || 0 )
        if ( JSON.stringify(this.getSelectAccount) != "{}" ){
            this.data = this.getSelectAccount
            AccountListStore.SetAccountListSelect({})
        }else{
            this.handleUpData()
        }
    }

    handleGetData(e:any){
        let key = e.other.state
        if ( key == "account" ){
            this.account = e.data
        }else if ( key == "name" ){
            this.name = e.data
        }else if ( key == "money" ){
            if (Number(e.data) > this.deposit) {
                Dialog.confirm({
                    title:"温馨提示",
                    message:"您的最大提现金额为: ￥" + this.deposit + " 元"
                }).then(res=>{
                    this.money = this.deposit + ''
                }).catch(cat=>cat)
            }else{
                this.money = e.data
            }
        }
    }

    handleClickAllMoney(){
        this.money = this.deposit + ''
    }

    handleUpData(){
        getDefaultAccount().then(res=>{
            this.data = res
        })
    }

    handleClickBtn(){
        setApplyMoney(this.data.id,this.money).then(res=>res).catch(cat=>cat)
    }

    @Watch("data",{ deep:true })
    changeData(newVal:any){
        if ( newVal && newVal["account"] ){
            this.account = setEncrypt({ data:newVal["account"],min:3,max:newVal["account"].length - 3 })
        }
        if ( newVal && newVal["phone"] ){
            this.name = setEncrypt({ data:newVal["name"],min:1,max:newVal["phone"].length - 1 })
        }
    }

    get getSelectAccount(){
        return AccountListStore.getAccountListSelect
    }
}
