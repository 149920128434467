
import Storage from "@/util/Storage";
import {ApplyMoney, DefaultAccount} from "@/Api/Money";
import {Dialog, Toast} from "vant";
import router from "@/router";

export function getDefaultAccount() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve(DefaultAccount({ userId,token }).then(res=>{
        if ( JSON.stringify(res.data) == "{}" ){
            Dialog.confirm({
                title:"温馨提示",
                message:"请先选择提现账户"
            }).then(res=>{
                router.push({
                    name:"accountList",
                    query:{
                        state:"select"
                    }
                })
            }).catch(cat=>cat)
        }
        return res.data
    }))
}

// 申请提现
export function setApplyMoney(withdrawalId = 0,money:string|number = 0) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    if ( 0 < money ){
        Toast("请输入提现金额")
        return Promise.reject()
    }
    Toast.loading({ message:"申请中~~",duration:0 })
    return Promise.resolve(ApplyMoney({ userId,token },{money, withdrawalId }).then(res=>{
        Toast.clear()
        Dialog.confirm({
            title:"温馨提示",
            message:"申请成功！等待工作人员审核提现"
        }).then(res=>{
            router.go(-1)
        }).catch(cat=>cat)
        return res.data
    }))
}
